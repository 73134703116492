.iauto-login-logo {
    padding-bottom: 40px;
}

.iauto-login-segment{
    padding: 11%!important;
}

.iauto-login-input{
    margin-bottom: 15px;
}

.iauto-login-page-wrapper {
    background-image: url('../../static/login.webp');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 100vh;
}