.iauto-layout-wrapper{
    background-color: #edeef0;
}

.iauto-layout-header{
    padding-top: 0;
    margin-top: 0;

}

.iauto-layout-content {
    margin: 10px;
}